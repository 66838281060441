<template>
  <div class="roomEquipment">
    <div class="nav" ref="nav" @click="handelSelect">
      <span> {{ roomInfo.text ? roomInfo.text : "请选择房号" }} </span>
      <img src="./img/arrow-down.png" alt="" />
    </div>
    <div class="model">
      <iframe
        :key="new Date().getTime()"
        id="iframe"
        width="100%"
        height="100%"
        :src="iframeUrl"
        frameborder="no"
      >
      </iframe>
    </div>
    <div class="my-equipment" v-if="equipmentData.length > 0">
      <div class="title">我的设备</div>
      <div class="list">
        <div
          class="item"
          v-for="(item, index) in equipmentData"
          :key="index"
          @click="handelClickROuter(item, item.id)"
        >
          <div class="item-t">
            <img
              :src="
                item.energyType == 'kt'
                  ? require('./img/air.png')
                  : require('./img/light.png')
              "
              alt=""
            />
            <div class="item-r">
              <div>{{ item.energyType == "kt" ? "空调" : "灯光" }}</div>
              <div>{{ item.name }}</div>
            </div>
          </div>
          <div class="item-b" v-if="item.energyType == 'kt'">
            <p v-for="(node, index1) in item.valueList" :key="index1">
              <span>{{ formatValue[node.tagCode] }}</span>
              <span
                >{{ node.val
                }}{{ node.tagCode == "settingtemperature" ? "°C" : "" }}</span
              >
            </p>
          </div>
          <div class="item-b" v-else>
            <p v-for="(node, index1) in item.valueList" :key="index1">
              <span>电灯状态：</span>
              <span>{{ node.val }}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="nodata" v-else>当前房间暂无可控制设备</div>
  </div>
</template>

<script>
import md5 from "js-md5";
import {
  getEquipmentList,
  getUserHouseInfoByUserCode,
  getOauthCode,
  getSpaceCut,
} from "./api";
export default {
  name: "roomEquipment",
  components: {},
  props: {},
  data() {
    return {
      iframeUrl: "",
      cut: "",
      dbId: "",
      currentIndex: 0,
      formatValue: {
        settingtemperature: "空调温度：",
        mode: "空调模式：",
        settingwind: "空调风量：",
      },
      navList: [],
      isExistCut: false,
      isExistDbId: false,
      equipmentData: [],
      code: "",
      roomInfo: {},
      origin: "",
    };
  },
  async created() {
    await this.isProduction();
    await this.getCode();
    if (localStorage.getItem("roomCode")) {
      this.roomInfo = JSON.parse(localStorage.getItem("roomCode"));
      this.getSpaceCut(this.roomInfo.code);
      this.getEquipmentList(this.roomInfo.code);
    }
    window.addEventListener("message", this.changeLoaded);
  },
  async mounted() {
    // this.getUserHouseInfoByUserCode();
  },
  computed: {
    userId() {
      return this.$store.state.userId;
    },
    // code() {
    //   return this.$store.state.code;
    // },
    userCode() {
      return this.$store.state.userCode;
    },
  },
  watch: {},
  methods: {
    isProduction() {
      const currentOrigin = window.location.origin;
      let productUrl = ["https://yshgjyd.nbhq.gov.cn"];
      if (productUrl.includes(currentOrigin)) {
        this.origin = "https://yshgjyd.nbhq.gov.cn";
      } else {
        this.origin = "https://cgzc-dev.zhuneng.cn";
      }
    },
    handelSelect() {
      this.$router.push({
        name: "roomSelect",
      });
    },
    //获取code
    async getCode(userId) {
      const params = {
        userId: "1697501921107587074",
        auth: 1,
      };
      const res = await this.$axios.post(getOauthCode, params);
      if (res.code == 200) {
        this.code = res.data;
      }
    },
    // 监听iframe资源是否加载完成，可以发送postmessage
    changeLoaded(e) {
      if (e.data && e.data.type === "e_loaded") {
        this.isLoad = true;
        if (this.isExistCut) {
          this.showCutIframe(this.cut);
        }
        if (this.isExistDbId) {
          this.showDbIdIframe(this.dbId);
        }
      }
    },
    //初始化模型
    initIframe(spaceId = "default") {
      // 应用码
      let appCode = "hzhc";
      // 应用密钥
      let secret = "D3M_e8096dc3cd6848038085ea16de7fd6b2";
      // 场景码，决定了加载哪个模型
      let sceneCode = spaceId;
      // 时间，单位秒
      let tm = parseInt(new Date().getTime() / 1000);
      // 签名，拼接appCode，sceneCode，secret，tm的字符串，然后进行md5加密 md5(appCode + sceneCode + secret + tm)
      md5(appCode + sceneCode + secret + tm);
      let sign = md5(appCode + sceneCode + secret + tm);
      this.iframeUrl =
        `https://cgzc-dev.zhuneng.cn/d3miframe/#/iframe?appCode=` +
        appCode +
        "&sceneCode=" +
        sceneCode +
        "&tm=" +
        tm +
        "&sign=" +
        sign;
    },
    showDbIdIframe(dbIds) {
      let iframe = document.getElementById("iframe").contentWindow;
      iframe.postMessage(
        // 格式：Array，对象的xyz为三维向量，value为切割值
        { type: "f_flyTo", data: { dbIds: [dbIds], modelIndex: 0 } },
        // origin 参数建议不要使用'*'，而是 iframe 的 origin，增强安全性
        "https://cgzc-dev.zhuneng.cn"
      );
    },
    showCutIframe(cut) {
      let iframe = document.getElementById("iframe").contentWindow;
      iframe.postMessage(
        // 格式：Array，对象的xyz为三维向量，value为切割值
        { type: "f_cut", data: [cut] },
        // origin 参数建议不要使用'*'，而是 iframe 的 origin，增强安全性
        "https://cgzc-dev.zhuneng.cn"
      );
    },
    //获取房屋的楼和楼层模型参数
    getSpaceCut(spaceId) {
      this.$axios
        .get(getSpaceCut, {
          params: {
            spaceId,
          },
        })
        .then((res) => {
          if (res.code == 200) {
            if (
              Object.prototype.hasOwnProperty.call(res.data, "cut") &&
              res?.data?.cut
            ) {
              this.cut = JSON.parse(res.data.cut);
              this.isExistCut = true;
            } else {
              this.isExistCut = false;
            }
            if (
              Object.prototype.hasOwnProperty.call(res.data, "dbId") &&
              res?.data?.dbId
            ) {
              this.dbId = res.data.dbId;
              this.isExistDbId = true;
            } else {
              this.isExistDbId = false;
            }
            this.initIframe(res.data.louSpaceId);
          }
        });
    },
    // //根据用户获取房屋信心
    // getUserHouseInfoByUserCode() {
    //   this.$axios
    //     .get(getUserHouseInfoByUserCode, {
    //       params: {
    //         userCode: this.userCode,
    //       },
    //     })
    //     .then((res) => {
    //       if (res.code == 200) {
    //         const { data } = res;
    //         this.navList = data;
    //         data[0].cut
    //           ? ((this.isExistCut = true), (this.cut = data[0].cut))
    //           : (this.isExist = false);
    //         data[0].dbId
    //           ? ((this.isExistDbId = true), (this.dbId = data[0].dbId))
    //           : (this.isExistDbId = false);
    //         // this.getEquipmentList(result.join(","), data[0].spaceId);
    //         this.getEquipmentList(data[0].spaceId);
    //         this.initIframe(data[0].louSpaceId);
    //       }
    //     });
    // },

    //获取设备列表
    getEquipmentList(spaceId) {
      const params = {
        spaceId,
      };
      this.$axios
        .get(
          "https://yshgjyd.nbhq.gov.cn/jieneng/smartdeviceproxy/admin/huacong/control/list",
          {
            params,
            headers: {
              code: this.code,
            },
          }
        )
        .then((res) => {
          if (res.code == 0) {
            const { data } = res;
            this.equipmentData = data.map((item) => {
              if (item.valueList && item.energyType == "kt") {
                item.valueList = item.valueList.filter((value) => {
                  return (
                    value.tagCode === "settingtemperature" ||
                    value.tagCode === "mode" ||
                    value.tagCode === "settingwind"
                  );
                });
              }
              return item;
            });
          }
        });
    },
    handelClickROuter(item, id) {
      if (item.energyType == "kt") {
        this.$router.push({
          name: "airConditionerSwitch",
          query: {
            id,
            code: this.code,
          },
        });
      } else {
        this.$router.push({
          name: "lightSwitch",
          query: {
            id,
            code: this.code,
          },
        });
      }
    },
    handelChangeNav(item, index) {
      this.currentIndex = index;
      this.initIframe(item.louSpaceId);
      const result = item.modelIds.filter((item) => item);
      // this.getEquipmentList(result.join(","), item.spaceId);
      this.getEquipmentList(item.spaceId);
      if (item.cut) {
        this.isExistCut = true;
        this.cut = item.cut;
      } else {
        this.isExistCut = false;
      }
      if (item.dbId) {
        this.isExistDbId = true;
        this.dbId = item.dbId;
      } else {
        this.isExistDbId = false;
      }
      const element = document.getElementById(`item_${index}`);
      const container = this.$refs.nav;
      const scrollLeftNum =
        element.offsetLeft -
        container.offsetWidth / 2 +
        element.offsetWidth / 2;
      container.scrollLeft = scrollLeftNum;
    },
  },
  beforeDestroy() {
    window.removeEventListener("message", this.changeLoaded);
  },
};
</script>

<style scoped lang="less">
.roomEquipment {
  width: 100%;
  height: 100vh;
  background-image: url("./img/bg.png");
  background-repeat: no-repeat;
  background-size: 100% 750px;
  box-sizing: border-box;
  padding: 48px 0 60px;
  display: flex;
  flex-direction: column;
  .nav {
    padding: 20px 64px;
    width: 100%;
    display: flex;
    overflow-x: scroll;
    align-items: center;
    flex-wrap: nowrap;
    box-sizing: border-box;
    scrollbar-width: none; /* firefox */
    -ms-overflow-style: none; /* IE 10+ */
    img {
      width: 32px;
      height: 32px;
      margin-left: 20px;
    }
    .item {
      box-sizing: border-box;
      width: auto;
      flex-shrink: 0;
      text-align: center;
      position: relative;
      &.active {
        span {
          font-size: 32px;
          font-weight: 600;
          color: #333333;
          line-height: 44px;
        }
      }
      &:not(:last-child) {
        margin-right: 68px;
      }
      span {
        font-size: 28px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.5);
        line-height: 40px;
      }
    }
  }
  .nav::-webkit-scrollbar {
    display: none;
  }

  .model {
    margin-top: 20px;
    min-height: 670px;
    padding: 0 30px 30px 30px;
    width: 100%;
    overflow: hidden;
    border-radius: 8px;
    flex: 1;
    box-sizing: border-box;
    .img {
      width: 100%;
      height: 100%;
      overflow: hidden;
      border-radius: 8px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    iframe {
      width: 100%;
      height: 100%;
      overflow: hidden;
      border-radius: 8px;
    }
  }
  .my-equipment {
    padding: 0 30px 30px 30px;
    margin-top: 48px;
    box-sizing: border-box;
    .title {
      margin-bottom: 20px;
      font-size: 32px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.85);
      line-height: 44p;
    }
    .list {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      box-sizing: border-box;
      gap: 30px;
      justify-content: space-between;
      .item {
        min-height: 330px;
        width: calc((100% - 30px) / 2);
        background: #ffffff;
        box-shadow: 0px 6px 18px 0px rgba(231, 232, 238, 0.5);
        border-radius: 16px;
        box-sizing: border-box;
        padding: 28px 28px 38px 28px;
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;

        .item-t {
          display: flex;
          align-items: center;
          margin-bottom: 46px;
          img {
            margin-right: 16px;
            width: 64px;
            height: 64px;
          }
          .item-r {
            div {
              font-size: 28px;
              font-family: PingFangSC, PingFang SC;
              font-weight: 500;
              color: rgba(0, 0, 0, 0.85);
              line-height: 40px;
            }
          }
          span {
            font-size: 28px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            color: rgba(0, 0, 0, 0.85);
            line-height: 40px;
          }
        }
        .item-b {
          p {
            &:not(:last-child) {
              margin-bottom: 16px;
            }
            span {
              font-size: 28px;
              font-family: PingFangSC, PingFang SC;
              font-weight: 400;
              color: rgba(0, 0, 0, 0.85);
              line-height: 40px;
              &:last-child {
                color: #fe4416;
              }
            }
          }
        }
      }
    }
  }
  .nodata {
    font-size: 28px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
    line-height: 40px;
    text-align: center;
    margin-top: 40px;
  }
}
</style>
